<template>
  <div>
    <div class="list-block">
      <BlogList blog-type="blog" :colName="blogColName"/>
    </div>
  </div>
</template>

<script>
import BlogList from '../components/BlogList'

export default {
  name: 'HomeView',
  components: {
    BlogList
  },
  data: function () {
    return {
      blogColName: process.env.VUE_APP_BLOG_PAGES
    }
  },
  created: async function () {
    document.title = 'imoniのトイBlog'
  },
}
</script>

<style scoped>

h1{
    margin-top: 0px;
    margin-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: medium;
    text-align: center;
    background: var(--main-color);
    color: white;
}

a {
  text-decoration: none;
}

.list-block {
  display: inline-block;
  width: 100%;
}
</style>
