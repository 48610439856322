<template>
  <div>
    <div v-if="mode == 1">
      <BlogPage :blogType="blogType" :colName="colName" :page="pageNum" @updateTitle="updateTitle"/>
    </div>
    <div v-else>
      <BlogList :blogType="blogType" :colName="colName"/>
    </div>
  </div>
</template>

<script>
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore/lite';

import BlogList from '../components/BlogList'
import BlogPage from '../components/BlogPage'

export default {
  name: 'BlogView',
  components: {
    BlogList,
    BlogPage
  },
  props: {
    blogType: {
      type: String,
      default: 'blog'
    },
    page: {
      type: String,
      default: 'all'
    }
  },
  data: function () {
    return {
      mode: 0,
      colName: '',
      pageNum: 0
    }
  },
  created: async function () {
    let subTitle = 'Blog'
    document.title = subTitle + ' | imoniのトイBlog'
    await this.loadData()
  },
  watch: {
    blogType: async function () {
      await this.loadData()
    },
    page: async function () {
      await this.loadData()
    }
  },
  methods: {
    updateTitle: function(subTitle) {
      document.title = subTitle + ' | imoniのトイBlog'
    },
    loadData: async function() {
      this.colName = process.env.VUE_APP_BLOG_PAGES
      var page = this.page
      if(isNaN(page)){
        this.mode = 0
        return
      }
      page = Number(page)
      let db = getFirestore()
      let colRef = collection(db, this.colName)
      const q = query(colRef, where('id', '==', page))
      let docSnapshot = await getDocs(q)
      if(docSnapshot.docs.length == 0){
        this.mode = 0
        return
      }
      this.mode = 1
      this.pageNum = page
    }
  }
}
</script>

<style scope>
</style>
