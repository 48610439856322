<template>
  <div class="blog-list">
    <ul @scroll="onScroll">
      <li v-for="item in list" :key="item.id" :style="getTagColor(item.tagColor)">
        <a :href="item.pageUrl">
          <div class="blog-list-item">
            <div class="blog-list-item-tag">
              {{item.tagName}}
            </div>
            <div class="blog-list-item-content">
              <img :src="item.image"/>
              <div class="blog-list-item-title">
                <time :datetime="item.createDatetime">{{item.create}}</time>
                  {{item.title}}
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { getFirestore, collection, query, getDocs, where, orderBy, limit } from 'firebase/firestore/lite';

export default {
  name: 'BlogList',
  props: {
    blogType: {
      type: String,
      default: 'blog'
    },
    colName: {
      type: String,
      default: 'blog'
    }
  },
  data: function () {
    return {
      list: [],
      minId: 9999,
      isScrollEnd: false
    }
  },
  created: async function () {
    await this.loadData()
  },
  watch: {
    colName: async function () {
      await this.loadData()
    }
  },
  methods: {
    loadData: async function() {
      let db = getFirestore()
      let colRef = collection(db, this.colName)
      const q = query(colRef, where('id', '<', this.minId), orderBy('id', 'desc'), limit(30))
      let docSnapshot = await getDocs(q)
      let docSnapshotT = await getDocs(collection(db, 'blog-tags'))
      let tags = {}
      docSnapshotT.docs.forEach(function(doc) {
        let docData = doc.data()
        tags[docData.id] = docData
      })
      docSnapshot.docs.forEach(function(doc) {
        let docData = doc.data()
        let data = {
          title: docData.title,
          image: docData.image,
          create: this.getDateStr(docData.create.toDate()),
          createDatetime: this.getDateStr2(docData.create.toDate()),
          pageUrl: '/' + this.blogType + '/' + docData.id,
          tagName: 'imoni',
          tagColor: 'blue'
        }
        if(this.minId == null || docData.id < this.minId){
          this.minId = docData.id
        }
        if(docData.tag_id in tags){
          let tag = tags[docData.tag_id]
          data.tagName = tag.name
          data.tagColor = tag.color
        }
        this.list.push(data)
      }.bind(this))
    },
    onScroll: function(event) {
      let isScrollEndOld = this.isScrollEnd
      this.checkScrollEnd(event)
      if (isScrollEndOld) {
        return
      }
      if (!this.isScrollEnd) {
        return
      }
      this.loadData()
    },
    checkScrollEnd(event) {
      const adjustmentValue = 5
      const positionWithAdjustmentValue =
        event.target.clientHeight + event.target.scrollTop + adjustmentValue
      this.isScrollEnd = positionWithAdjustmentValue >= event.target.scrollHeight
    },
    getDateStr: function(date) {
      return 'YYYY年MM月DD日 hh:mm:ss'
                .replace(/YYYY/g, date.getFullYear())
                .replace(/MM/g, this.zFill(1 + date.getMonth()))
                .replace(/DD/g, this.zFill(date.getDate()))
                .replace(/hh/g, this.zFill(date.getHours()))
                .replace(/mm/g, this.zFill(date.getMinutes()))
                .replace(/ss/g, this.zFill(date.getSeconds()))
    },
    getDateStr2: function(date) {
      return 'YYYY-MM-DDThh:mm:ss'
                .replace(/YYYY/g, date.getFullYear())
                .replace(/MM/g, this.zFill(1 + date.getMonth()))
                .replace(/DD/g, this.zFill(date.getDate()))
                .replace(/hh/g, this.zFill(date.getHours()))
                .replace(/mm/g, this.zFill(date.getMinutes()))
                .replace(/ss/g, this.zFill(date.getSeconds()))
    },
    getTagColor: function(color) {
      return '--main-color: var(--color-' + color + ')'
    },
    zFill: function(value) {
      return ('0' + value).slice(-2)
    }
  }
}
</script>

<style scoped>
.blog-list ul{
  margin: 0;
  padding: 0;
  padding-right: 5px;
  list-style: none;
  overflow-y: scroll;
  height: calc(100vh - 120px);
}
.blog-list li{
  width: calc(100% - 10px);
  margin-bottom: 5px;
  padding: 0;
  border: solid 4px var(--main-color);
  border-radius: 4px;
}
.blog-list a{
  text-decoration: none;
}
.blog-list-item{
  width: 100%;
  font-size: larger;
  font-weight: bold; 
  color: var(--main-color);
}
.blog-list-item:hover{
  background-color: #eeeeee;
}

.blog-list-item-tag{
  width: 100%;
  background-color: var(--main-color);
  border-bottom: solid 4px var(--main-color);
  color: white;
  font-size: small;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.blog-list-item-content{
  display: flex;
  text-align: left;
}
.blog-list-item-content time{
  display: block;
  font-size: small;
  font-weight: normal; 
}
.blog-list-item-content img{
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.blog-list-item-title{
  padding: 5px;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
