<template>
  <div id="app">
    <header>
      <div class="title">
        <div><a href="/">imoniのトイBlog</a></div>
      </div>
    </header>
    <div class="views">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
:root {
  --color-deeporange: #ff5722;
  --color-red: #f44336;
  --color-pink: #e91e63;
  --color-purple: #9c27b0;
  --color-deeppurple: #673ab7;
  --color-indigo: #3f51b5;
  --color-blue: #2196f3;
  --color-lightblue: #00bcd4;
  --color-cyan: #00bcd4;
  --color-teal: #009688;
  --color-green: #4caf50;
  --color-lightgreen: #8bc34a;
  --color-lime: #cddc39;
  --color-yellow: #ffeb3b;
  --color-amber: #ffc107;
  --color-orange: #ff9800;
  --color-brown: #795548;
  --color-bluegray: #607d8b;
  --color-gray: #9e9e9e;
  --color-black: black;
  --main-color: var(--color-green);
}

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap');

#app {
  font-family: "Noto Sans JP", Meiryo, "ＭＳ Ｐゴシック", Avenir, Helvetica, Arial, sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

header {
  position: sticky;
  top: 0px;
  background: white;
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 10px;
}

.router-link-exact-active{
  background-color: var(--main-color);
  color: white !important;
  border-bottom: solid 3px var(--main-color) !important;
}
.router-link-exact-active:visited{
  background-color: var(--main-color);
  color: white !important;
}

button{
  font-family: "Noto Sans JP", Meiryo, "ＭＳ Ｐゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-display: swap;
  padding-left: 10px;
  padding-right: 10px;
  font-size: large;
  background: var(--main-color);
  color: white;
  cursor: pointer;
  border: none;
}
button:disabled {
  background: var(--color-gray);
  opacity: 0.6;
  cursor: auto;
}
input{
  font-family: "Noto Sans JP", Meiryo, "ＭＳ Ｐゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-display: swap;
  font-size: medium;
}
textarea{
  font-family: "Noto Sans JP", Meiryo, "ＭＳ Ｐゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-display: swap;
  font-size: medium;
}
.grecaptcha-badge { visibility: hidden; }
</style>

<style scoped>
.views {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}
.title a {
  color: var(--main-color);
  text-decoration: none;
}
.title {
  margin: 0;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  margin-top: -10px;
  border-bottom: solid 5px var(--main-color);
}
</style>

