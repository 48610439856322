<template>
  <div class="blog-page" :style="tagColorStyle">
    <div class="blog-title">
      <ul>
        <li>
          <div class="blog-title-item">
            <div class="blog-title-item-content">
              <div class="blog-title-item-tag">
                {{tagName}}
              </div>
              <time :datetime="createDatetime">{{create}}</time>
                {{title}}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div>
      <div v-html="content"></div>
    </div>
    <br>
  </div>
</template>

<script>
import { marked } from 'marked'

import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore/lite';

export default {
  name: 'BlogPage',
  props: {
    blogType: {
      type: String,
      default: 'blog'
    },
    page: {
      type: Number,
      default: 0
    },
    colName: {
      type: String,
      default: 'blog'
    }
  },
  data: function () {
    return {
      tagColor: 'blue',
      title: '',
      tagName: 'imoni',
      create: '',
      createDatetime: '',
      content: '',
      comments: [],
      comment: {
        name: '',
        content: ''
      }
    }
  },
  created: async function () {
    this.initMarked()
    await this.loadData()
  },
  watch: {
    page: async function () {
      await this.loadData()
    }
  },
  computed: {
    isSendButtonEnable() {
      if(this.comment.content == ''){
        return false
      }
      return true
    },
    tagColorStyle: function(){
      return '--main-color: var(--color-' + this.tagColor + ')'
    }
  },
  methods: {
    initMarked: function() {
      marked.setOptions({
        pedantic: false,    // Markdown.pl準拠を無効
        gfm: true,          // GitHub Flavored Markdown を有効
        breaks: true        // 改行文字での改行を有効(半角スペース2つは不要)
      })
    },
    loadData: async function() {
      this.title = ''
      this.create = ''
      this.createDatetime = ''
      this.content = ''
      let db = getFirestore()
      let colRef = collection(db, this.colName)
      const q = query(colRef, where('id', '==', this.page))
      let docSnapshot = await getDocs(q)
      if(docSnapshot.docs.length == 0){
        return
      }
      let docData = docSnapshot.docs[0].data()
      this.title = docData.title
      this.$emit("updateTitle", this.title);
      this.content = marked(docData.content)
      this.create = this.getDateStr(docData.create.toDate())
      this.createDatetime = this.getDateStr2(docData.create.toDate())
      this.comments = docData.comments

      let colRefT = collection(db, 'blog-tags')
      const qT = query(colRefT, where('id', '==', docData.tag_id))
      let docSnapshotT = await getDocs(qT)
      if(docSnapshotT.docs.length == 0){
        return
      }
      let docDataT = docSnapshotT.docs[0].data()
      this.tagName = docDataT.name
      this.tagColor = docDataT.color
    },
    getDateStr: function(date) {
      return 'YYYY年MM月DD日 hh:mm:ss'
                .replace(/YYYY/g, date.getFullYear())
                .replace(/MM/g, this.zFill(1 + date.getMonth()))
                .replace(/DD/g, this.zFill(date.getDate()))
                .replace(/hh/g, this.zFill(date.getHours()))
                .replace(/mm/g, this.zFill(date.getMinutes()))
                .replace(/ss/g, this.zFill(date.getSeconds()))
    },
    getDateStr2: function(date) {
      return 'YYYY-MM-DDThh:mm:ss'
                .replace(/YYYY/g, date.getFullYear())
                .replace(/MM/g, this.zFill(1 + date.getMonth()))
                .replace(/DD/g, this.zFill(date.getDate()))
                .replace(/hh/g, this.zFill(date.getHours()))
                .replace(/mm/g, this.zFill(date.getMinutes()))
                .replace(/ss/g, this.zFill(date.getSeconds()))
    },
    zFill: function(value) {
      return ('0' + value).slice(-2)
    }
  }
}
</script>

<style scoped>
.blog-page ::v-deep(h1){
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  background: var(--main-color);
  color: white;
}
.blog-page ::v-deep(h2){
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  color: var(--main-color);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--main-color);
}
.blog-page ::v-deep(h3){
  font-size: large;
  color: var(--main-color);
  line-height: 100%;
}
.blog-page ::v-deep(p){
  font-size: medium;
  line-height: 30px;
}
.blog-page ::v-deep(img){
    max-width: calc(100% - 10px);
    margin: 10px 5px;
}
.blog-page ::v-deep(a:link){
  text-decoration:underline;
  color: var(--main-color);
}
.blog-page ::v-deep(a:visited){
  text-decoration:underline;
  color: var(--main-color);
}
.blog-page ::v-deep(a:hover){
  color: var(--main-color);
}

.blog-title ul{
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-title li{
  margin-bottom: 5px;
  padding: 0;
  border: solid 4px var(--main-color);
  border-radius: 4px;
}
.blog-title a{
  text-decoration: none;
}
.blog-title-item{
  display: table;
}
.blog-title-item-tag{
  display: table-cell;
  width: 800px;
  max-width: 800px;
  background-color: var(--main-color);
  border-bottom: solid 4px var(--main-color);
  color: white;
  font-size: small;
  font-weight: bold; 
  text-align: center;
  vertical-align: middle;
}
.blog-title-item-content{
  display: table-cell;
  width: 100%;
  padding: 0px;
  font-size: larger;
  font-weight: bold; 
  color: var(--main-color);
  text-align: center;
}
.blog-title-item-content time{
  display: block;
  font-size: small;
  font-weight: normal;
}
</style>
